import React from "react";
import { Link } from "react-router-dom";

import styles from "./ProjectFundingPage.module.scss";

const ProjectFundingPage = () => {
  const fundingFormURL =
    "https://docs.google.com/forms/d/e/1FAIpQLSchJxQh4P7PR58oAjqgOul2x7s1kGAGno0lQYiMdyTg7GL_Pg/viewform";

  return (
    <div className={styles.funding}>
      <div className={styles.steps}>
        <h1 className={styles.steps__title}> Project Funding </h1>

        <p className={styles.steps__stepInfo}>
          Have a cool project idea? We can help you get started with up to $500
          of pre-seed funding and access a network of mentors to support you.
          There are three simple steps for getting your project funded all
          described in the project proposal guidelines.
        </p>

        <p className={styles.steps__stepInfo}>
          📑 Step 1: Fill out the project proposal form. Filling this out in
          detail is crucial for a successful funding application. Describe your
          project, how much funding you need, and what the funds will be used
          for.
        </p>

        <p className={styles.steps__stepInfo}>
          💸 Step 2: Meet with the funding team. Go over your application with
          the funding team to clarify any questions we have. Establish
          milestones to ensure successful completion of the project.
        </p>

        <p className={styles.steps__stepInfo}>
          ✅ Step 3: Meet during milestone check-ins with the funding team.
          Periodically check-in based on the milestones set with the funding
          team.
        </p>
      </div>

      <div className={styles.eligibility}>
        <h1 className={styles.eligibility__title}> Eligibility </h1>

        <p className={styles.eligibility__info}>
          All members of LaunchDeck are eligible for project funding.
        </p>
      </div>

      <Link to={{ pathname: fundingFormURL }} target="_blank">
        <button className={styles.page__applyButton}>
          Apply for Funding Here
        </button>
      </Link>
    </div>
  );
};

export default ProjectFundingPage;
