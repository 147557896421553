import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";

import amazonLogo from "../../assets/images/company_logo/amazon.svg";
import courseraLogo from "../../assets/images/company_logo/coursera.svg";
import googleLogo from "../../assets/images/company_logo/google.svg";
import gsLogo from "../../assets/images/company_logo/gs.svg";
import khanAcademyLogo from "../../assets/images/company_logo/khan_academy.svg";
import lyftLogo from "../../assets/images/company_logo/lyft.svg";
import mckinseyLogo from "../../assets/images/company_logo/mckinsey.svg";
import opentableLogo from "../../assets/images/company_logo/opentable.svg";
import salesforceLogo from "../../assets/images/company_logo/salesforce.svg";
import thielFoundationLogo from "../../assets/images/company_logo/thiel_foundation.png";
import uberLogo from "../../assets/images/company_logo/uber.svg";
import GetConnectedImage from "../../assets/images/Connect.png";
import GetFundedImage from "../../assets/images/Funding.png";
import GetInspiredImage from "../../assets/images/Inspire.png";
import LandingImage from "../../assets/images/Landing.png";
import TopicBlock from "./TopicBlock/TopicBlock";

import styles from "./HomePage.module.scss";

const HomePage = () => {
  const fbGroupUrl = "https://www.facebook.com/groups/swatlaunchdeck/";
  return (
    <div className={styles.home}>
      <div className={styles.banner}>
        <img src={LandingImage} alt="landing"></img>

        <Link to={{ pathname: fbGroupUrl }} target="_blank">
          <button>Join Here</button>
        </Link>

        <p>Swarthmore's Largest Tech and Entrepreneurship Club</p>
      </div>

      <div className={styles.topicsSection}>
        <TopicBlock
          title="Get Connected"
          text="Connect with successful entrepreneurs and leaders in the world of tech and entrepreneurship at our Weekly LaunchTalk speaker series."
          buttonShow={true}
          image={GetConnectedImage}
          buttonText="View Our Past Speakers"
          url="/resources"
          isExternalLink={false}
        ></TopicBlock>

        <TopicBlock
          title="Get Funded"
          text="Have an idea? We can help you get started with up to $500 of pre-seed funding and access a network of mentors to support you."
          buttonShow={true}
          image={GetFundedImage}
          buttonText="Learn More"
          url="/programs"
          isExternalLink={false}
        ></TopicBlock>

        <TopicBlock
          title="Get Inspired"
          text="Join hands-on workshops for a variety of topics ranging from how to land an internship to technical skills such as mobile and web development."
          buttonShow={true}
          image={GetInspiredImage}
          buttonText="Join Our Facebook Group"
          url={fbGroupUrl}
          isExternalLink={true}
        ></TopicBlock>
      </div>

      <Divider style={{ width: "90%" }} />

      <div className={styles.companiesSection}>
        <p className={styles.companiesSection__title}>
          We have invited speakers who have worked at
        </p>

        <div className={styles.companiesSection__logos}>
          <img
            className={styles.company__logo__width}
            src={amazonLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__width}
            src={courseraLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__width}
            src={googleLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__height}
            src={gsLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__width}
            src={khanAcademyLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__height}
            src={lyftLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__width}
            src={mckinseyLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__width}
            src={opentableLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__height}
            src={salesforceLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__width}
            src={thielFoundationLogo}
            alt=""
          ></img>

          <img
            className={styles.company__logo__last}
            src={uberLogo}
            alt=""
          ></img>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
