import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AboutPage from "./components/AboutPage/AboutPage";
import Footer from "./components/Footer/Footer";
import HomePage from "./components/HomePage/HomePage";
import Navbar from "./components/NavBar/Navbar";
import NewsletterSubscribe from "./components/NewsletterSubscribe/NewsletterSubsribe";
import ProjectFundingPage from "./components/other/ProjectFundingPage/ProjectFundingPage";
import ProgramsPage from "./components/ProgramsPage/ProgramsPage";
import ResourcesPage from "./components/ResourcesPage/ResourcesPage";

import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <div>
      <Router>
        <Navbar />

        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/programs" component={ProgramsPage} />
          <Route exact path="/resources" component={ResourcesPage} />
          <Route exact path="/Project_Funding" component={ProjectFundingPage} />
          <Route exact path="/subscribe" component={NewsletterSubscribe} />
          <Route component={HomePage} />
        </Switch>

        <Footer />
      </Router>
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);
