import React from "react";

import FundingImage from "../../assets/images/Program-Funding.png";
import LaunchFellowsImage from "../../assets/images/Program-LaunchFellow.jpeg";
import LaunchTalksImage from "../../assets/images/Program-LaunchTalk.jpeg";
import TopicBlock from "../HomePage/TopicBlock/TopicBlock";

import ProgramsPageCSS from "./ProgramsPage.module.scss";

const ProgramsPage = () => {
  return (
    <div className={ProgramsPageCSS.container_programs}>
      <TopicBlock
        title="LaunchTalks"
        text="LaunchDeck hosts a casual fireside chat with a guest who works in the world of tech or entrepreneurship. Come learn, and ask any questions you have!"
        buttonShow={false}
        image={LaunchTalksImage}
      ></TopicBlock>

      <TopicBlock
        title="Project Funding"
        text="Have a startup idea you want to try to build out or is there a new skill or technology you want to learn? Apply to get funding from LaunchDeck."
        buttonShow={false}
        image={FundingImage}
      ></TopicBlock>

      <TopicBlock
        title="Launch Fellows"
        text="Launch Fellows are underclassmen LaunchDeck members selected to participate in a mentorship program with an upperclassmen with experience in their field of interests."
        buttonShow={false}
        image={LaunchFellowsImage}
      ></TopicBlock>
    </div>
  );
};

export default ProgramsPage;
