import React from "react";
import { Link } from "react-router-dom";

import "./TopicBlock.scss";

export const TopicBlock = ({
  title,
  text,
  buttonShow,
  image,
  buttonText,
  url,
  isExternalLink,
}) => {
  let actionButton;
  if (isExternalLink) {
    actionButton = (
      <Link
        to={{ pathname: url }}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <button
          className="tb_action_button"
          style={{
            visibility: buttonShow ? "visible" : "hidden",
          }}
        >
          {buttonText}
        </button>
      </Link>
    );
  } else {
    actionButton = (
      <Link to={{ pathname: url }} style={{ textDecoration: "none" }}>
        <button
          className="tb_action_button"
          style={{
            display: buttonShow ? "inherit" : "none",
          }}
          // takes you to the top of the routed page
          onClick={() => window.scrollTo(0, 0)}
        >
          {buttonText}
        </button>
      </Link>
    );
  }
  return (
    <>
      <div className="tb_container">
        <p className="tb_title">{title}</p>

        <img className="tb_image" src={image} alt=""></img>

        <p className="tb_text">{text}</p>

        {actionButton}
      </div>
    </>
  );
};

export default TopicBlock;
