import React from "react";

import andyLeePic from "../../assets/images/launchtalk_speakes/andy_lee.png";
import cathyPolinskyPic from "../../assets/images/launchtalk_speakes/cathy_polinsky.png";
import danielleStratchmanPic from "../../assets/images/launchtalk_speakes/danielle_stratchman.png";
import dianaMartschenkoPic from "../../assets/images/launchtalk_speakes/diana_martschenko.png";
import valentinSanchezPic from "../../assets/images/launchtalk_speakes/getulio_valentin_sánchez.png";
import jeremySchifeilingPic from "../../assets/images/launchtalk_speakes/jeremy_schifeling.png";
import jesseLeePic from "../../assets/images/launchtalk_speakes/jesse_lee.png";
import joshColinPic from "../../assets/images/launchtalk_speakes/josh_colin.png";
import katherineLamPic from "../../assets/images/launchtalk_speakes/katherine_lam.png";
import lainyPainterPic from "../../assets/images/launchtalk_speakes/lainy_painter.png";
import markHainsPic from "../../assets/images/launchtalk_speakes/mark_hains.png";
import meiriAntoPic from "../../assets/images/launchtalk_speakes/meiri_anto.png";
import milesSkorpenPic from "../../assets/images/launchtalk_speakes/miles_skorpen.png";
import owensKweePic from "../../assets/images/launchtalk_speakes/owens_kwee.png";
import shannonHsuPic from "../../assets/images/launchtalk_speakes/shannon_hsu.png";
import LaunchTalkCard from "./LaunchTalkCard/LaunchTalkCard";

import ResourcesPageCSS from "./ResourcesPage.module.scss";

const ResourcesPage = () => {
  return (
    <div className={ResourcesPageCSS.resources}>
      <div className={ResourcesPageCSS.launchTalks}>
        <h1 className={ResourcesPageCSS.launchTalks__title}>
          Past LaunchTalks
        </h1>

        <div className={ResourcesPageCSS.launchTalks__cards}>
          <LaunchTalkCard
            heading="Lainy Painter"
            subHeading="Partner @ Craft Ventures + Former IBD (Technology) @ Goldman Sachs"
            date="11.18.21"
            image={lainyPainterPic}
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Mark Hanis '05"
            subHeading="Co-founder @ Progressive Shopper + Associate Fellow @ Johns Hopkins SAIS"
            date="11.16.21"
            image={markHainsPic}
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Getulio Valentin Sánchez '21"
            subHeading="SWE @ Google"
            date="11.04.21"
            image={valentinSanchezPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com"
            // linkedInUrl="https://www.linkedin.com/in/gvso1/"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Katherine Lam '11"
            subHeading="PM @ Google (YouTube)"
            date="10.19.21"
            image={katherineLamPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com"
            // linkedInUrl="https://www.linkedin.com/in/katjlam/"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Josh Collin '20"
            subHeading="Co-founder of Bioloop Sleep + Former Analyst @ Goldman Sachs"
            date="09.23.21"
            image={joshColinPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com"
            // linkedInUrl="https://www.linkedin.com/in/joshua-collin/"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Miles Skorpen '09"
            subHeading="VP of Product @ Opentable + Former PM @ eBay"
            date="04.29.21"
            image={milesSkorpenPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com"
            // linkedInUrl="https://www.linkedin.com/in/milesskorpen/"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Diana Martschenko '18"
            subHeading="SDE @ Amazon"
            date="04.15.21"
            image={dianaMartschenkoPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com"
            // linkedInUrl="https://www.linkedin.com/in/dianamartschenko/"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Asha Owens + Rebecca Kwee"
            subHeading="Co-Founders @ BestFit"
            date="04.08.21"
            image={owensKweePic}
            // buttonText="View Recording"
            // url="https://www.youtube.com/watch?v=CJWsSv7hMew"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Shannon Hsu"
            subHeading="PM @ Lyft + Harvard MBA"
            date="04.03.21"
            image={shannonHsuPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com/watch?v=F7m32R_mJ0s"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Jesse Lee"
            subHeading="Head of Sales Strategy & Operations @ Google + Wharton MBA"
            date="03.18.21"
            image={jesseLeePic}
            // buttonText="View Recording"
            // url="https://www.youtube.com/watch?v=yrNVpErXkzI"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Jeremy Schifeiling '03"
            subHeading="Principal Product Marketing Manager @ Khan Academy"
            date="03.11.21"
            image={jeremySchifeilingPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com/watch?v=58L2XukkcO0"
          ></LaunchTalkCard>

          {/* <LaunchTalkCard
            heading="Upperclassmen SWE Panel"
            subHeading="Incoming SWE Interns @ Microsoft + Amazon + Salesforce"
            date="03.04.21"
            image={LaunchDeckLogo}
            buttonText="View Recording"
            url="https://www.youtube.com"
          ></LaunchTalkCard> */}

          <LaunchTalkCard
            heading="Meiri Anto '17"
            subHeading="Senior SWE @ Dreambound + Former SWE @ Coursera"
            date="02.25.21"
            image={meiriAntoPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com/watch?v=8ck-XPeENyY"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Andy Lee '16"
            subHeading="Founder @ Basis"
            date="02.23.21"
            image={andyLeePic}
            // buttonText="View Recording"
            // url="https://www.youtube.com/watch?v=8ck-XPeENyY"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Danielle Strachman"
            subHeading="Co-Founder @ 1517 Fund + Former Program Director @ Thiel Foundation"
            date="02.18.21"
            image={danielleStratchmanPic}
            // buttonText="View Recording"
            // url="https://www.youtube.com/watch?v=EW_mXqTwpO0&feature=youtu.be"
          ></LaunchTalkCard>

          <LaunchTalkCard
            heading="Cathy Polinsky '99"
            subHeading="Former SVP of Engineering @ Salesforce + Former CTO @ Stitch Fix"
            date="11.19.20"
            image={cathyPolinskyPic}
            url="https://www.youtube.com/watch?v=UqCdFDang5o"
            buttonText="View Recording"
          ></LaunchTalkCard>
        </div>
      </div>
    </div>
  );
};

export default ResourcesPage;
