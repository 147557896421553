import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/Symbol Logo.png";

import styles from "./Footer.module.scss";

const Footer = () => {
  const linkedinUrl =
    "https://www.linkedin.com/company/swatlaunchdeck/mycompany/";
  const facebookUrl = "https://www.facebook.com/groups/swatlaunchdeck";
  const mailUrl = "mailto:swatlaunchdeck@gmail.com";

  return (
    <div className={styles.footer}>
      <img src={Logo} alt="Logo" className={styles.footer__logo}></img>

      <div className={styles.footer__socials}>
        <Link to={{ pathname: facebookUrl }} target="_blank">
          <i
            className="footer-icons-facebook"
            class="fab fa-facebook fa-2x"
          ></i>
        </Link>

        <Link to={{ pathname: linkedinUrl }} target="_blank">
          <i
            className="footer-icons-linkedin"
            class="fab fa-linkedin fa-2x"
          ></i>
        </Link>

        <Link to={{ pathname: mailUrl }} target="_blank">
          <i className="footer-icons-gmail" class="far fa-envelope fa-2x"></i>
        </Link>
      </div>

      <h1 className={styles.footer__title}> Swarthmore LaunchDeck ©2021 </h1>
    </div>
  );
};

export default Footer;
