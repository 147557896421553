import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import styles from "./FieldsForm.module.scss";

const CLASS_YEARS = {
  option1: "2023",
  option2: "2024",
  option3: "2025",
  option4: "2026",
};

// represented as numerical values due to the MailChimp API :(
const interestAreas = {
  software: 1,
  product: 2,
  entrepreneurship: 4,
};

const FieldsForm = ({ setAlertState, status, subscribe }) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      EMAIL: "",
      FNAME: "",
      LNAME: "",
      CYEAR: "",
      AREAS: [],
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log(data, e);

    data.AREAS.forEach((area) => (data[`group[89927][${area}]`] = area));
    delete data.AREAS;

    subscribe(data);

    setAlertState({
      severity: status === "success" ? "success" : "error",
      message:
        status === "success"
          ? "Thanks, you successfully subscribed to our mailing list!"
          : "Sorry, something went wrong. Try again later!",
      hidden: false,
    });

    window.scrollTo(0, 0);

    if (status === "success") {
      setTimeout(() => history.push("/"), 1500);
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <div className={styles.form}>
      <div className={styles.fields}>
        <FormControl>
          <TextField
            {...register("FNAME", {
              required: true,
            })}
            sx={{
              ".MuiInputLabel-root.Mui-focused": {
                color: "#9c3d3d",
              },
              ".MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#9c3d3d",
                },
              },
            }}
            variant="outlined"
            label="First Name"
          />

          {errors.FNAME && (
            <FormHelperText
              sx={{
                fontFamily: "AirbnbCereal",
                fontSize: "14px",
              }}
              error={errors.FNAME !== undefined}
            >
              {errors.FNAME?.type === "required"
                ? "This Field is required"
                : "Invalid email"}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <TextField
            {...register("LNAME", {
              required: true,
            })}
            sx={{
              ".MuiInputLabel-root.Mui-focused": {
                color: "#9c3d3d",
              },
              ".MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#9c3d3d",
                },
              },
            }}
            variant="outlined"
            label="Last Name"
          />

          {errors.LNAME && (
            <FormHelperText
              sx={{
                fontFamily: "AirbnbCereal",
                fontSize: "14px",
              }}
              error={errors.LNAME !== undefined}
            >
              {errors.LNAME?.type === "required"
                ? "This Field is required"
                : "Invalid email"}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <TextField
            {...register("EMAIL", {
              required: true,
              pattern: /\S+@\S+\.\S+/,
            })}
            sx={{
              ".MuiInputLabel-root.Mui-focused": {
                color: "#9c3d3d",
              },
              ".MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#9c3d3d",
                },
              },
            }}
            variant="outlined"
            label="Email"
          />

          {errors.EMAIL && (
            <FormHelperText
              sx={{
                fontFamily: "AirbnbCereal",
                fontSize: "14px",
              }}
              error={errors.EMAIL !== undefined}
            >
              {errors.EMAIL?.type === "required"
                ? "This Field is required"
                : "Invalid email"}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormLabel
            sx={{
              fontFamily: "AirbnbCereal-Medium",
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.6)",
              },
            }}
            id="demo-controlled-radio-buttons-group"
          >
            Class Year
          </FormLabel>

          {errors.CYEAR && (
            <FormHelperText
              sx={{
                fontFamily: "AirbnbCereal",
                fontSize: "14px",
              }}
              error={errors.CYEAR !== undefined}
            >
              This field is required
            </FormHelperText>
          )}

          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
          >
            <FormControlLabel
              {...register("CYEAR", { required: true })}
              label={CLASS_YEARS.option1}
              value={CLASS_YEARS.option1}
              control={
                <Radio
                  sx={{
                    "&.MuiButtonBase-root.Mui-checked": {
                      color: "#9c3d3d",
                    },
                  }}
                />
              }
            />

            <FormControlLabel
              {...register("CYEAR", { required: true })}
              label={CLASS_YEARS.option2}
              value={CLASS_YEARS.option2}
              control={
                <Radio
                  sx={{
                    "&.MuiButtonBase-root.Mui-checked": {
                      color: "#9c3d3d",
                    },
                  }}
                />
              }
            />

            <FormControlLabel
              {...register("CYEAR", { required: true })}
              label={CLASS_YEARS.option3}
              value={CLASS_YEARS.option3}
              control={
                <Radio
                  sx={{
                    "&.MuiButtonBase-root.Mui-checked": {
                      color: "#9c3d3d",
                    },
                  }}
                />
              }
            />

            <FormControlLabel
              {...register("CYEAR", { required: true })}
              label={CLASS_YEARS.option4}
              value={CLASS_YEARS.option4}
              control={
                <Radio
                  sx={{
                    "&.MuiButtonBase-root.Mui-checked": {
                      color: "#9c3d3d",
                    },
                  }}
                />
              }
            />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel
            sx={{
              fontFamily: "AirbnbCereal-Medium",
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.6)",
              },
            }}
            id="demo-controlled-radio-buttons-group"
          >
            Areas of Interest (select at least 1)
          </FormLabel>

          {errors.AREAS && (
            <FormHelperText
              sx={{
                fontFamily: "AirbnbCereal",
                fontSize: "14px",
              }}
              error={errors.AREAS !== undefined}
            >
              This field is required
            </FormHelperText>
          )}

          <FormGroup>
            <FormControlLabel
              {...register("AREAS", {
                required: true,
              })}
              label="Software Engineering"
              value={interestAreas.software}
              control={
                <Checkbox
                  sx={{
                    "&.MuiButtonBase-root.Mui-checked": {
                      color: "#9c3d3d",
                    },
                  }}
                />
              }
            />

            <FormControlLabel
              {...register("AREAS", {
                required: true,
              })}
              label="Entrepreneurship"
              value={interestAreas.entrepreneurship}
              control={
                <Checkbox
                  sx={{
                    "&.MuiButtonBase-root.Mui-checked": {
                      color: "#9c3d3d",
                    },
                  }}
                />
              }
            />

            <FormControlLabel
              {...register("AREAS", {
                required: true,
              })}
              label="Product Management"
              value={interestAreas.product}
              control={
                <Checkbox
                  sx={{
                    "&.MuiButtonBase-root.Mui-checked": {
                      color: "#9c3d3d",
                    },
                  }}
                />
              }
            />
          </FormGroup>
        </FormControl>
      </div>

      <Button
        sx={{
          width: "250px",
          backgroundColor: "#9c3d3d",
          marginTop: "25px",
          alignSelf: "center",
          fontFamily: "AirbnbCereal-Medium",

          "&:hover": {
            color: "#9c3d3d",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderColor: "#9c3d3d",
          },
        }}
        variant="contained"
        onClick={handleSubmit(onSubmit, onError)}
      >
        Subscribe
      </Button>
    </div>
  );
};

export default FieldsForm;
