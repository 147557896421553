import { useState } from "react";
import MailchimpSubscribeForm from "react-mailchimp-subscribe";
import { Alert, Collapse } from "@mui/material";

import FieldsForm from "./FieldsForm/FieldsForm";

const MAILCHIMP_URL = process.env.REACT_APP_NEXT_PUBLIC_MAILCHIMP_URL;

import styles from "./NewsletterSubscribe.module.scss";

const NewsletterSubscribe = () => {
  const [alertState, setAlertState] = useState({
    severity: "success",
    message: "",
    hidden: true,
  });

  return (
    <div className={styles.newsletter}>
      <Collapse in={!alertState.hidden}>
        <Alert severity={alertState.severity}>{alertState.message}</Alert>
      </Collapse>

      <h1 className={styles.newsletter__title}>
        Subscribe to LaunchDeck's Newsletter
      </h1>

      <p className={styles.newsletter__description}>
        {`LaunchDeck is Swarthmore’s largest technology and entrepreneurship club.
        We want to foster a community of aspiring entrepreneurs, developers,
        designers, etc. at Swarthmore. We provide funding for student projects,
        host speaker events, and host hackathons. Join our mailing list for
        weekly LaunchPad newsletter emails, information about upcoming events,
        and more cool content!`}
      </p>

      <MailchimpSubscribeForm
        url={MAILCHIMP_URL}
        render={({ status, subscribe, message }) => (
          <>
            <FieldsForm
              subscribe={subscribe}
              status={status}
              setAlertState={setAlertState}
            />
          </>
        )}
      />
    </div>
  );
};

export default NewsletterSubscribe;
