import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";

import styles from "./ValueBlock.module.scss";

export const ValueBlock = ({
  title,
  text,
  reverse = false,
  image,
  showDivider = true,
  buttonText,
  buttonLink,
}) => {
  const renderInfo = () => {
    return (
      <div className={styles.info}>
        <h1 className={styles.info__title}> {title} </h1>

        <p className={styles.info__description}> {text} </p>

        {buttonLink && buttonText && (
          <Link to={buttonLink} target="_blank">
            <button className={styles.info__button}> {buttonText} </button>
          </Link>
        )}
      </div>
    );
  };

  const renderVisual = () => {
    return (
      <div className={styles.visual}>
        <img className={styles.visual__image} src={image} alt=""></img>
      </div>
    );
  };

  return (
    <div className={styles.block}>
      <div className={styles.content}>
        {reverse ? renderVisual() : renderInfo()}

        {reverse ? renderInfo() : renderVisual()}
      </div>

      {showDivider && <Divider style={{ width: "90%" }} />}
    </div>
  );
};

export default ValueBlock;
