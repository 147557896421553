import React from "react";

import styles from "./BoardMemberCard.module.scss";

const BoardMemberCard = ({
  heading,
  subHeading,
  date,
  image,
  buttonText,
  url,
  linkedInUrl,
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <h1 className={styles.content__heading}> {heading} </h1>

        <h2 className={styles.content__subHeading}>{subHeading}</h2>

        <p className={styles.content__date}> {date} </p>
      </div>

      <div className={styles.visual}>
        <img
          className={styles.visual__image}
          src={image}
          alt="LaunchDeck Logo"
        ></img>
      </div>
    </div>
  );
};

export default BoardMemberCard;
