import React from "react";
import { Link } from "react-router-dom";

import styles from "./MobileMenu.module.scss";

const MobileMenu = ({ closeMobileMenu }) => {
  const linkedinUrl =
    "https://www.linkedin.com/company/swatlaunchdeck/mycompany/";
  const facebookUrl = "https://www.facebook.com/groups/swatlaunchdeck";
  const mailUrl = "mailto:swatlaunchdeck@gmail.com";

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.items}>
        <div className={styles.menuItem}>
          <Link
            to="/resources"
            className={styles.menuItem__label}
            onClick={closeMobileMenu}
          >
            Events
          </Link>
        </div>

        <div className={styles.menuItem}>
          <Link
            to="/programs"
            className={styles.menuItem__label}
            onClick={closeMobileMenu}
          >
            Programs
          </Link>
        </div>

        <div className={styles.menuItem}>
          <Link
            to="/about"
            className={styles.menuItem__label}
            onClick={closeMobileMenu}
          >
            About
          </Link>
        </div>
      </div>

      <div className={styles.socials}>
        <div className={styles.social}>
          <a
            href={facebookUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.social__logo}
          >
            <i className="fab fa-facebook fa-2x" />
          </a>
        </div>

        <div className={styles.social}>
          <a
            href={linkedinUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.social__logo}
          >
            <i className="fab fa-linkedin fa-2x" />
          </a>
        </div>

        <div className={styles.social}>
          <a
            href={mailUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.social__logo}
          >
            <i className="far fa-envelope fa-2x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
