import React from "react";
import { Link } from "react-router-dom";

import styles from "./Menu.module.scss";

const Menu = () => {
  return (
    <div className={styles.menu}>
      <div className={styles.menuItem}>
        <Link to="/resources" className={styles.menuItem__label}>
          Events
        </Link>
      </div>

      <div className={styles.menuItem}>
        <Link to="/programs" className={styles.menuItem__label}>
          Programs
        </Link>
      </div>

      <div className={styles.menuItem}>
        <Link to="/about" className={styles.menuItem__label}>
          About
        </Link>
      </div>
    </div>
  );
};

export default Menu;
