import React from "react";

import giancarloPic from "../../assets/images/board_member_pics/giancarlo.png";
import jasonPic from "../../assets/images/board_member_pics/jason.png";
import koyoPic from "../../assets/images/board_member_pics/koyo.png";
import livPic from "../../assets/images/board_member_pics/liv.png";
import michaelPic from "../../assets/images/board_member_pics/michael.png";
import theronPic from "../../assets/images/board_member_pics/theron.png";
import collaborateImage from "../../assets/images/illustrations/collaborate.svg";
import launchImage from "../../assets/images/illustrations/launch.svg";
import Logo from "../../assets/images/Symbol Logo.png";
import launchDeckLogo from "../../assets/images/Symbol Logo.png";
import ValueBlock from "../HomePage/ValueBlock/ValueBlock";
import BoardMemberCard from "./BoardMemberCard/BoardMemberCard";

import styles from "./AboutPage.module.scss";

const AboutPage = () => {
  const mailUrl = "mailto:swatlaunchdeck@gmail.com";
  return (
    <div className={styles.aboutPage}>
      <div className={styles.infoBlocks}>
        <ValueBlock
          title="What is LaunchDeck?"
          text="LaunchDeck is Swarthmore's largest technology and entrepreneurship
          club. We provide students with resources and network to develop
          idea, learn new skills, prepare for successful careers, and meet
          like-minded technology and entrepreneurship enthusiasts at
          Swarthmore."
          image={launchImage}
        ></ValueBlock>

        <ValueBlock
          title="Our Mission"
          text="We are fostering a community of aspiring entrepreneurs, developers,
          and designers at Swarthmore. We enable students to succeed in their
          career and entrepreneurial ventures by hosting speaker events,
          coordinating mentorship programs, and funding student projects."
          image={collaborateImage}
          reverse={true}
        ></ValueBlock>
      </div>

      <div className={styles.board}>
        <p className={styles.board__title}>Leadership Team 2021-22</p>

        <div className={styles.board__members}>
          <BoardMemberCard
            heading= "Theron Mansilla '23"
            subHeading="President"
            image={theronPic}
            // text = {<a href="mailto:john.doe@example.com">Send email</a>}
            // mailimg = {Logo}
          ></BoardMemberCard>
          <BoardMemberCard
            heading="Jason Ono '23"
            subHeading="Vice President"
            image={jasonPic}
          ></BoardMemberCard>

          <BoardMemberCard
            heading="Michael Yan '23"
            subHeading="Heaf of Marketing"
            image={michaelPic}
          ></BoardMemberCard>

          <BoardMemberCard
            heading="Olivia Medeiros-Sakimoto '25"
            subHeading="Head of Design"
            image={livPic}
          ></BoardMemberCard>

          <BoardMemberCard
            heading="Koyo Asakawa '25"
            subHeading="Webmaster"
            image={koyoPic}
          ></BoardMemberCard>

          <BoardMemberCard
            heading="Giancarlo Mansilla '25"
            subHeading="Marketing Coodinator"
            image={giancarloPic}
          ></BoardMemberCard>

          
        </div>
      </div>
    </div>
  );
};
export default AboutPage;
