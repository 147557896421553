import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/Symbol Logo.png";
import Menu from "./Menu/Menu";
import MobileMenu from "./MobileMenu/MobileMenu";

import styles from "./Navbar.module.scss";

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleMobileMenuClick = () => setMobileOpen(!mobileOpen);
  const closeMobileMenu = () => setMobileOpen(false);

  return (
    <div className={styles.container}>
      <nav className={styles.navbar}>
        <Link to="/" className={styles.navbarLogo}>
          <img src={logo} alt="Logo" className={styles.navbarLogo__image}></img>

          <p className={styles.navbarLogo__title}> LaunchDeck </p>
        </Link>

        <div className={styles.mobileMenuIcon} onClick={handleMobileMenuClick}>
          <i className={mobileOpen ? "fas fa-times" : "fas fa-bars"} />
        </div>

        <Menu />

        {mobileOpen && <MobileMenu closeMobileMenu={closeMobileMenu} />}
      </nav>
    </div>
  );
};

export default Navbar;
